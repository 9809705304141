<template>
  <img
    :src="`/logos/${fileName}`"
    alt="BeyondBreath Logo"
  >
</template>

<script setup lang="ts">
const props = defineProps({
  type: {
    type: String,
    default: 'long_light',
    validator(value: string) {
      return ['long_light', 'long_dark', 'short_light', 'short_dark'].includes(value)
    },
  },
})

const fileName = computed(() => {
  switch (props.type) {
    case 'long_light':
      return 'BeyondBreath-Logo-white.png'
    case 'long_dark':
      return 'BeyondBreath-Logo-dark.png'
    case 'short_light':
      return 'BeyondBreath-Logo-white_short.png'
    case 'short_dark':
      return 'BeyondBreath-Logo-dark_short.png'
    default:
      return 'BeyondBreath-Logo-blackyellow.png'
  }
})
</script>
